@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importar los estilos de slick-carousel */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.project-image-container {
  width: 200px; /* Ancho fijo para todas las imágenes */
  height: 100px; /* Alto fijo para todas las imágenes */
  overflow: hidden; /* Oculta cualquier parte que sobresalga */
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Mantiene la proporción y cubre el área del contenedor */
  filter: grayscale(100%); /* Aplica el efecto en blanco y negro */
  transition: filter 0.3s; /* Añade una transición suave */
}

.project-image:hover {
  filter: grayscale(0%); /* Restaura el color al pasar el mouse */
}

.client-logo {
  width: 70px; /* Ajusta al tamaño deseado */
  height: 50px; /* Ajusta al tamaño deseado */
  filter: grayscale(100%); /* Aplica el efecto en blanco y negro */
  transition: filter 0.3s; /* Añade una transición suave */
}

.client-logo:hover {
  filter: grayscale(0%); /* Restaura el color al pasar el mouse */
}

